import * as React from "react";
import { useTranslation } from "react-i18next";

import Menu from "@tele2/t2-ui-kit/build/Icon/icons/Menu";

import {
  HeaderButton,
  MainMenuMobile,
  MainMenuItemProps,
  PopupManager,
} from "@tele2/t2-ui-kit";

const MainMenuButton: React.FC<{
  menuItems?: MainMenuItemProps[];
  onMainMenuChange: (item: MainMenuItemProps) => void;
}> = ({ menuItems, onMainMenuChange }) => {
  const { t } = useTranslation();

  return (
    <PopupManager
      element={() => (
        <HeaderButton aria-label={t("general.menu")}>
          <Menu />
        </HeaderButton>
      )}
    >
      {(onClose) => (
        <MainMenuMobile
          onMainMenuChange={onMainMenuChange}
          mainMenu={menuItems}
          onClose={onClose}
          title={t("general.myPages")}
        />
      )}
    </PopupManager>
  );
};

export default MainMenuButton;
