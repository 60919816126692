import React from "react";
import { useTranslation } from "react-i18next";
import useSession, {
  SessionStatus,
  useInvalidateSession,
  User,
} from "../api/session/useSession";
import { ContentWrapper } from "../App-style";
import LoaderComponent from "../Components/LoaderComponent/LoaderComponent";

interface SessionContextProps {
  authenticated: boolean;
  valid: boolean;
  user?: User;
}

export const SessionContext = React.createContext<SessionContextProps>({
  valid: false,
  authenticated: false,
});

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [login, setLogin] = React.useState<string>("");
  const [version, setVersion] = React.useState<string>("");
  const { data } = useSession();
  const invalidateSession = useInvalidateSession();

  const onFocus = React.useCallback(() => {
    invalidateSession();
  }, [invalidateSession]);

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (data.user && data.user.login !== login) {
      if (login) {
        // If it is a different user
        window.location.href = "/";
        return;
      }
      setLogin(data.user.login);
    }

    // Reload page if app version changed
    setVersion(data.version);
    if (version && version !== data.version) {
      window.location.reload();
      return;
    }

    if (data.sessionStatus === SessionStatus.UPDATING) {
      const handler = setTimeout(() => invalidateSession(), 5_000);
      return () => clearTimeout(handler);
    }
  }, [data, invalidateSession, login, version]);

  React.useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  const contextValue = React.useMemo<SessionContextProps>(() => {
    return {
      authenticated: Boolean(data && data.sessionStatus !== SessionStatus.NONE),
      valid: data?.sessionStatus === SessionStatus.VALID,
      user: data?.user,
    };
  }, [data]);

  React.useEffect(() => {
    document.documentElement.setAttribute(
      "data-authenticated",
      contextValue.authenticated ? "true" : "false"
    );
  }, [contextValue.authenticated]);

  return (
    <SessionContext.Provider value={contextValue}>
      {data ? (
        children
      ) : (
        <ContentWrapper>
          <LoaderComponent
            minHeight={"300px"}
            text={t("general.loadingProfile")}
          />
        </ContentWrapper>
      )}
    </SessionContext.Provider>
  );
};
export default Provider;
