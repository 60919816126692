import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMessage } from "../../Components/Message/Message";

const httpCall = async (token: string) => {
  const formData = new FormData();
  formData.set("token", token);
  await axios.post("/auth/login-bankid", formData);
};

const useBankIdLoginToken = () => {
  const { mutateAsync } = useMutation<void, AxiosError, string>({
    mutationFn: httpCall,
  });
  const message = useMessage();
  const { t } = useTranslation();
  const authenticateWithToken = useCallback(
    async (token: string) => {
      try {
        await mutateAsync(token);
        return true;
      } catch (_) {
        message({
          interval: 5000,
          message: t("error.500.message"),
          theme: "alert",
        });
        return false;
      }
    },
    [message, mutateAsync, t]
  );
  return { authenticateWithToken };
};

export default useBankIdLoginToken;
