import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import * as React from "react";
import { QUERY_KEY as BANK_ID_LOGIN_COLLECT_QUERY_KEY } from "../bankid/useBankIdLoginCollect";
import { QUERY_KEY as BANK_ID_LOGIN_QR_QUERY_KEY } from "../bankid/useBankIdLoginQr";
import { QUERY_KEY as PRINCIPAL_MOULES_QUERY_KEY } from "../subscriptions/usePrincipalModules";
import { QUERY_KEY as SUBSCRIPTION_OVERVIEW_QUERY_KEY } from "../subscriptions/useSubscriptionsOverview";

export enum SessionStatus {
  NONE = "NONE",
  UPDATING = "UPDATING",
  VALID = "VALID",
}

export interface User {
  firstName: string;
  lastName: string;
  login: string;
  bankIdAuth: boolean;
  email?: string;
}

export interface SessionModel {
  sessionStatus: SessionStatus;
  user: User;
  timestamp: number;
  version: string;
}

const get = async () => {
  const { data } = await axios.get<SessionModel>("/api/session");
  // It is not clear why this happens, but it does at least when running locally.
  if (typeof data === "string") {
    throw new Error("Invalid session data");
  }
  return data;
};

const QUERY_KEY = "session-data";
const useSession = () =>
  useQuery<SessionModel, AxiosError>({
    queryKey: [QUERY_KEY],
    queryFn: get,
    refetchInterval: 300_000,
  });
export default useSession;

export const useInvalidateSession = () => {
  const queryClient = useQueryClient();
  return React.useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);
};

export const useInvalidateAllData = () => {
  const queryClient = useQueryClient();
  return React.useCallback(async () => {
    queryClient.removeQueries({
      queryKey: [BANK_ID_LOGIN_COLLECT_QUERY_KEY],
    });
    queryClient.removeQueries({
      queryKey: [BANK_ID_LOGIN_QR_QUERY_KEY],
    });
    await queryClient.invalidateQueries();
  }, [queryClient]);
};

export const useRemoveAppData = () => {
  const queryClient = useQueryClient();
  return React.useCallback(async () => {
    // Remove all but subscriptions, principals and session.
    // They need to be re-fetched when invalidated otherwise no subscriptions will show.
    queryClient.removeQueries({
      predicate: (query) =>
        !(
          query.queryKey[0] === SUBSCRIPTION_OVERVIEW_QUERY_KEY ||
          query.queryKey[0] === PRINCIPAL_MOULES_QUERY_KEY ||
          query.queryKey[0] === QUERY_KEY
        ),
    });
    await queryClient.invalidateQueries();
  }, [queryClient]);
};
