import * as React from "react";
import useSubsId from "../util/useSubsId";
import useSubscriptionsOverview, {
  SubscriptionModel,
} from "../api/subscriptions/useSubscriptionsOverview";

const useSelectedSubscription = (): SubscriptionModel | undefined => {
  const subsId = useSubsId();
  const { data: subs } = useSubscriptionsOverview(false);
  return React.useMemo(() => {
    if (!subs) return undefined;
    return subs.find((value) => value.subsId === subsId);
  }, [subs, subsId]);
};

export default useSelectedSubscription;
