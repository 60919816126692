import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

type Endpoint = "AUTH" | "API";

export interface Parameters {
  orderRef: string;
  endpoint: Endpoint;
}

export interface BankIdQrModel {
  image: string;
}

const httpCall = async (param: Parameters) => {
  const url =
    param.endpoint === "AUTH"
      ? `/auth/bankid/qr/${param.orderRef}`
      : `/api/bankid/qr/${param.orderRef}`;
  const { data } = await axios.get<BankIdQrModel>(url);
  return data;
};

export const QUERY_KEY = "bank-id-login-qr";

const useBankIdLoginQr = (param: Parameters) => {
  return useQuery<BankIdQrModel, AxiosError>({
    queryKey: [QUERY_KEY, param.orderRef],
    queryFn: () => httpCall(param),
    throwOnError: false,
  });
};

export default useBankIdLoginQr;
