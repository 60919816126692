import { Button, H2 } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Endpoint, TokenType } from "../../api/bankid/useBankIdLoginAuth";
import useBankIdLoginCancel from "../../api/bankid/useBankIdLoginCancel";
import useBankIdLoginCollect, {
  HintCode,
  Status,
} from "../../api/bankid/useBankIdLoginCollect";
import useBankIdLoginToken from "../../api/bankid/useBankIdLoginToken";
import { useInvalidateAllData } from "../../api/session/useSession";
import LoaderComponent from "../../Components/LoaderComponent/LoaderComponent";
import { useMessage } from "../../Components/Message/Message";
import LoginBankIdQr from "./LoginBankIdQr";

interface Props {
  orderRef: string;
  tokenType: TokenType;
  autoStartToken: string;
  clear: () => void;
  endpoint: Endpoint;
}

interface State {
  status: Status;
  hintCode: HintCode;
  token: string;
  count: number;
}

const initState: State = {
  status: "",
  hintCode: "",
  token: "",
  count: 0,
};

const LoginBankIdCollect: React.FC<Props> = ({
  orderRef,
  tokenType,
  autoStartToken,
  clear,
  endpoint,
}) => {
  const message = useMessage();
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>(initState);
  const { refetch } = useBankIdLoginCollect({
    orderRef,
    endpoint,
  });
  const { authenticateWithToken } = useBankIdLoginToken();
  const invalidateAllData = useInvalidateAllData();
  const { cancelBankId } = useBankIdLoginCancel();

  React.useEffect(() => {
    if (tokenType === "AUTO_START") {
      window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
    }
  }, [autoStartToken, tokenType]);

  React.useEffect(() => {
    const timeout = setTimeout(async () => {
      const { data } = await refetch();
      if (data?.status === "FAILED") {
        clear();
        message({
          interval: 3000,
          message: t("general.error"),
          theme: "alert",
        });
        return;
      }
      if (data?.status === "COMPLETE") {
        if (endpoint === "AUTH") {
          const success = await authenticateWithToken(data?.token);
          if (success) {
            // Reload on success to reload third party scripts
            window.location.reload();
          } else {
            clear();
          }
        } else {
          window.location.href = "/";
        }
        return;
      }
      setState({
        ...state,
        status: data?.status || state.status,
        hintCode: data?.hintCode || state.hintCode,
        count: state.count + 1,
      });
    }, 2000);
    return () => clearTimeout(timeout);
  }, [
    message,
    t,
    refetch,
    authenticateWithToken,
    clear,
    endpoint,
    autoStartToken,
    tokenType,
    state,
    invalidateAllData,
  ]);

  const onCancel = React.useCallback(async () => {
    await cancelBankId({ orderRef, endpoint });
    clear();
  }, [cancelBankId, clear, endpoint, orderRef]);

  return (
    <>
      <H2 marginBottom="xl">
        {t(
          tokenType === "AUTO_START"
            ? "module.login.bankIdTitle"
            : "module.login.bankIdTitleQr"
        )}
      </H2>
      {state.status === "COMPLETE" ? (
        <>
          <LoaderComponent
            minHeight={"200px"}
            text={t("general.please.wait")}
          />
        </>
      ) : state.status === "PENDING" && state.hintCode === "USER_SIGN" ? (
        <>
          <LoaderComponent
            minHeight={"200px"}
            text={t("module.login.bankIdVerify")}
          />
        </>
      ) : tokenType === "QR_CODE" ? (
        <>
          <LoginBankIdQr
            orderRef={orderRef}
            endpoint={endpoint}
            onCancel={onCancel}
          />
        </>
      ) : (
        <>
          <LoaderComponent
            minHeight={"200px"}
            text={t("module.login.bankIdOpen")}
          />
        </>
      )}
      {state.status !== "COMPLETE" && (
        <Button block marginBottom="md" secondary onClick={onCancel}>
          {t("general.cancel")}
        </Button>
      )}
    </>
  );
};

export default LoginBankIdCollect;
