import { HeaderUserMenu, margin, padding, Text } from "@tele2/t2-ui-kit";

import AccountBox from "@tele2/t2-ui-kit/build/Icon/icons/AccountBoxOutlined";
import SettingsApplications from "@tele2/t2-ui-kit/build/Icon/icons/SettingsApplicationsOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useLogout from "../../api/auth/useLogout";
import { SessionContext } from "../../Contexts/SessionContext";

import ChangeLanguage from "./components/ChangeLanguage";

export const DetailsWrapper = styled.div`
  padding: 0 ${padding.MD};
  > * {
    margin-top: ${margin.MD};
    margin-bottom: ${margin.MD};
  }
`;

export const DetailsHeader = styled(Text)`
  margin-top: 0;
`;

const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const { user } = React.useContext(SessionContext);
  const { logout } = useLogout();

  const userMenuOptions = (onClose?: () => void) => [
    {
      component: <ChangeLanguage />,
      icon: <SettingsApplications />,
      label: t("settings.language.change"),
    },
    {
      clickHandler: async () => {
        await logout();
      },
      icon: <AccountBox />,
      label: t("general.logout"),
    },
  ];

  return (
    <HeaderUserMenu
      backLabel={t("general.back")}
      menuItems={userMenuOptions}
      mobileTitle={t("settings.overview.title")}
      user={{ firstName: user?.firstName, lastName: user?.lastName }}
    />
  );
};

export default UserMenu;
