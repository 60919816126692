import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { color, Flex, H1, Link, Text } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useHeader } from "../SiteHeader/HeaderContext";

const Wrapper = styled.div`
  margin-top: min(10vh, 150px);
`;

const Hero = styled(H1)`
  font-style: normal;
  font-weight: normal;
  font-size: 188px;
  line-height: 188px;
  color: ${color.BRAND_PREMIUM_GREY};
`;

const ErrorLocationText = styled(Text)`
  text-align: center;
  word-break: break-all;
  max-width: 600px;
`;
const ErrorPage: React.FC<{
  resetErrorCode?: () => void;
  status: number;
  errorMessage?: string;
}> = ({ status, resetErrorCode, errorMessage }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { reset } = useQueryErrorResetBoundary();
  const firstRenderRef = useRef(true);
  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    reset();
    resetErrorCode?.();
    return () => {
      resetErrorCode?.();
      reset();
    };
  }, [location, reset, resetErrorCode]);

  const { clearHeader, setDefaultHeader } = useHeader();
  React.useEffect(() => {
    setDefaultHeader();
    return () => {
      clearHeader();
    };
  }, [clearHeader, location, setDefaultHeader]);

  return (
    <Wrapper>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Hero>{status > 0 ? status : ""}</Hero>
        {status > 0 && <H1>{t(`error.${status}.header`)}</H1>}
        <Text xl>
          {status > 0 ? t(`error.${status}.message`) : t("error.500.message")}
        </Text>
        {errorMessage && <Text xl>{errorMessage}</Text>}
        <ErrorLocationText>{window.location.href}</ErrorLocationText>
        <Link marginTop="xl" onClick={() => (window.location.href = "/")}>
          {t("general.toStart")}
        </Link>
      </Flex>
    </Wrapper>
  );
};

export default ErrorPage;
