import * as React from "react";
import { useTranslation } from "react-i18next";
import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "../../AppRoutes";

import BreadCrumbs from "../SiteHeader/components/BreadCrumbs";

type HeaderTypes = {
  backgroundImage?: string;
  breadcrumb?: React.ReactNode;
  content?: React.ReactNode;
  subHeader?: React.ReactNode;
  title?: string;
};

export type HeaderContextType = {
  header: HeaderTypes;
  clearHeader: () => void;
  setDefaultHeader: (props?: HeaderTypes) => void;
  setHeader: React.Dispatch<React.SetStateAction<HeaderTypes>>;
};

const defaultHeader = {
  backgroundImage: undefined,
  breadcrumb: undefined,
  content: undefined,
  subHeader: undefined,
  title: undefined,
};

export const HeaderContext = React.createContext<HeaderContextType>({
  header: defaultHeader,
  clearHeader: () => null,
  setDefaultHeader: () => null,
  setHeader: () => null,
});

export const useHeader = (): HeaderContextType => {
  return React.useContext(HeaderContext);
};

export const HeaderProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [header, setHeader] = React.useState<HeaderTypes>({});
  const { t } = useTranslation();
  const location = useLocation();
  const title = React.useMemo(() => {
    const matchedRoutes = matchRoutes(routes, location);
    const routeObj = matchedRoutes ? matchedRoutes[0].route : null;
    return routeObj && routeObj.title ? t(routeObj.title) : "";
  }, [location, t]);

  const setDefaultHeader = React.useCallback(
    (optionalHeader?: HeaderTypes) => {
      setHeader({
        breadcrumb: <BreadCrumbs />,
        title: title,
        ...optionalHeader,
      });
    },
    [title]
  );

  const clearHeader = React.useCallback(() => {
    setHeader(defaultHeader);
  }, []);

  const contextValue = React.useMemo<HeaderContextType>(() => {
    return { header, clearHeader, setDefaultHeader, setHeader };
  }, [clearHeader, header, setDefaultHeader]);

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};
