import { H2 } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";

import useOtpLogin from "../../api/auth/useOtpMethod";
import { useMessage } from "../../Components/Message/Message";
import LoginOtp1FA from "./LoginOtp1FA";
import LoginInitOtp from "./LoginOtpInit";

const LoginOtp: React.FC = () => {
  const message = useMessage();
  const { t } = useTranslation();
  const { mutate, reset, isPending, isSuccess, data } = useOtpLogin();
  React.useEffect(() => {
    if (data) {
      if (data.error) {
        reset();
        message({
          interval: 5000,
          message: data.error,
          theme: "alert",
        });
      } else {
        message({
          interval: 3000,
          message: data.message,
          theme: "positive",
        });
        const timeout = setTimeout(() => {
          reset();
          message({
            interval: 5000,
            message: t("module.login.otpCodeTimeout"),
            theme: "com",
          });
        }, data.timeout * 1000);
        return () => clearTimeout(timeout);
      }
    }
    return undefined;
  }, [data, message, reset, t]);

  return (
    <>
      <H2 marginBottom="xl">{t("module.login.withOtp")}</H2>
      {isPending || isSuccess ? (
        <LoginOtp1FA {...data} />
      ) : (
        <LoginInitOtp onSubmit={mutate} />
      )}
    </>
  );
};

export default LoginOtp;
