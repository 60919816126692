import { DropDown } from "@tele2/t2-ui-kit";
import axios from "axios";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface LanguageOption {
  dropdownLabel: string;
  option: string;
}

const Wrapper = styled.div`
  flex: 0.5;
`;

const LoginLanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation();

  const availLngs = i18n.options.supportedLngs
    ? i18n.options.supportedLngs?.filter((lang: string) => {
        return lang !== "cimode";
      })
    : [];

  const languageOptions = availLngs.map<LanguageOption>((lang) => ({
    dropdownLabel: t(`settings.language.${lang}`),
    option: lang,
  }));

  const currentLanguage = languageOptions.findIndex(
    (opt) => opt.option === i18n.language
  );

  return (
    <Wrapper>
      <DropDown
        onSelect={async (value: unknown) => {
          await axios.post(`/api/localization?locale=${value}`);
          window.location.reload();
        }}
        options={languageOptions}
        selectedOption={languageOptions[currentLanguage]}
        widthCss="100%"
      />
    </Wrapper>
  );
};

export default LoginLanguageSelector;
