import * as React from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../LoaderComponent/LoaderComponent";

interface SuspenseBoundaryProps {
  fallback?: React.SuspenseProps["fallback"];
}
const SuspenseBoundary: React.FC<PropsWithChildren<SuspenseBoundaryProps>> = ({
  fallback,
  children,
}) => {
  return (
    <React.Suspense fallback={fallback || <SuspenseComponent />}>
      {children}
    </React.Suspense>
  );
};
export default SuspenseBoundary;

const SuspenseComponent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LoaderComponent minHeight="300px" text={t("general.loadingPleaseWait")} />
  );
};
