import { Utility } from "@tele2/t2-ui-kit";
import * as React from "react";
import {
  LoginMessageNotification,
  OperatingTicket,
} from "../../api/notifications/useNotifications";
import { t } from "i18next";

export enum NotificationState {
  NOT_READ = "NOT_READ",
  READ = "READ",
}

export enum NotificationsViewState {
  NONE = "NONE",
  ALL = "ALL",
  NOT_READ = "NOT_READ",
  NEW = "NEW",
}

export enum NotificationType {
  OPERATING_TICKETS = "OPERATING_TICKETS",
  BROWSER_WARNING = "BROWSER_WARNING",
  LOGIN_MESSAGE = "LOGIN_MESSAGE",
}

export interface NotificationProps {
  id: string;
  title: string;
  body: string | React.JSX.Element;
  color?: Utility["color"];
  link?: string;
  state?: NotificationState;
  type: NotificationType;
}

export const colorFromLevel = (level: string): Utility["color"] | undefined => {
  switch (level) {
    case "alert-success": {
      // SUCCESS
      return "BRAND_GREEN";
    }
    case "alert-info": {
      // INFO
      return "FUNCTIONAL_BLUE";
    }
    case "alert-warning": {
      // WARNING
      return "FUNCTIONAL_ORANGE";
    }
    case "alert-danger": {
      // DANGER
      return "FUNCTIONAL_RED";
    }
  }
  return;
};

const getLevelFromColor = (level?: string) => {
  switch (level) {
    case "FUNCTIONAL_BLUE":
      return 1;
    case "FUNCTIONAL_ORANGE":
      return 2;
    case "FUNCTIONAL_RED":
      return 3;
    case "BRAND_GREEN":
    default:
      return 0;
  }
};

export function getBrowserWarningNotification(): NotificationProps | undefined {
  const ua = window.navigator.userAgent;
  const isLessThanIE11 = ua.indexOf("MSIE ") >= 0;
  const isIE11 = !!(ua.match(/Trident/) && ua.match(/rv[ :]11/));

  if (isLessThanIE11) {
    return {
      id: NotificationType.BROWSER_WARNING,
      type: NotificationType.BROWSER_WARNING,
      title: "Browser warning",
      color: colorFromLevel("alert-info"),
      body: t("module.browser.error") ?? "",
    };
  }

  if (isIE11) {
    return {
      id: NotificationType.BROWSER_WARNING,
      type: NotificationType.BROWSER_WARNING,
      title: "Browser warning",
      color: colorFromLevel("alert-info"),
      body: t("module.browser.error.ie11") ?? "",
    };
  }
  return;
}

export const sortNotificationsByLevel = (
  notifications: NotificationProps[]
): NotificationProps[] =>
  notifications.sort(
    (a, b) => getLevelFromColor(b.color) - getLevelFromColor(a.color)
  );

export const getLoginMessageNotification = (
  loginMessage: LoginMessageNotification
): NotificationProps => {
  return {
    id: NotificationType.LOGIN_MESSAGE + "_" + loginMessage.id,
    type: NotificationType.LOGIN_MESSAGE,
    title: t("module.notification.login_messages"),
    color: colorFromLevel(loginMessage.level),
    body: (
      <div
        dangerouslySetInnerHTML={{
          __html: loginMessage.translation,
        }}
      />
    ),
  };
};

export const getOperationalDisturbancesNotification = (
  loginMessages: OperatingTicket[]
): NotificationProps[] => {
  if (!loginMessages || loginMessages.length === 0) {
    return [];
  }
  return loginMessages.map((n) => {
    return {
      id: NotificationType.OPERATING_TICKETS + "_" + n.id,
      type: NotificationType.OPERATING_TICKETS,
      title: t("module.notification.operating_information_count"),
      color: colorFromLevel("alert-warning"),
      body: n.info,
    };
  });
};

interface NotificationStorageProps {
  state: NotificationState;
}

export const saveNotificationsToStorage = (
  userLogin: string,
  notifications: NotificationProps[]
) => {
  const parsedStorage = getUserLocalStorage(userLogin);
  const notificationsMap: Record<string, NotificationStorageProps> = {};
  notifications.forEach(
    (n) =>
      (notificationsMap[n.id] = {
        state: n.state || NotificationState.NOT_READ,
      })
  );
  parsedStorage["notification"] = notificationsMap;
  localStorage.setItem(userLogin, JSON.stringify(parsedStorage));
};

export const markNotificationAsDismissed = (userLogin: string, id: string) => {
  const parsedStorage = getUserLocalStorage(userLogin);
  if (parsedStorage["notification"]) {
    const notification = parsedStorage["notification"][id];
    if (notification) {
      notification.state = NotificationState.READ;
    }
  }
  localStorage.setItem(userLogin, JSON.stringify(parsedStorage));
};

export const getNotificationsFromStorage = (
  userLogin: string
): Record<string, NotificationStorageProps> => {
  const parsedStorage = getUserLocalStorage(userLogin);
  if (parsedStorage["notification"]) {
    return parsedStorage["notification"] as Record<
      string,
      NotificationStorageProps
    >;
  }
  return {};
};

const getUserLocalStorage = (userLogin: string) => {
  const userStorage = localStorage.getItem(userLogin);
  if (userStorage) {
    return JSON.parse(userStorage);
  }
  return {};
};
