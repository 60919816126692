import { Button, Text, TextInput } from "@tele2/t2-ui-kit";
import ArrowForward from "@tele2/t2-ui-kit/build/Icon/icons/ArrowForward";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useOtp1FA from "../../api/auth/useOtp1FA";
import { OtpModel } from "../../api/auth/useOtpMethod";

import CenterText from "../../Components/CenterText/CenterText";
import onEnter from "../../util/onEnter";

const LoginOtp1FA: React.FC<Partial<OtpModel>> = (props) => {
  const [password, setPassword] = React.useState("");
  const { mutate, isPending } = useOtp1FA();
  const { t } = useTranslation();
  const userName = props.otpSmsNumber || "";
  return (
    <>
      <Text xl>{userName ? t("module.otp.ingress", [userName]) : ""}</Text>
      <TextInput
        block
        marginTop="md"
        marginBottom="md"
        label={t("module.otp.code")}
        value={password}
        disabled={isPending}
        onKeyDown={onEnter(
          () => password.length && userName && mutate({ userName, password })
        )}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        autoComplete="one-time-code"
      />
      <Button
        block
        marginBottom="md"
        onClick={() => mutate({ userName, password })}
        disabled={!password.length || !userName || isPending}
      >
        <CenterText>
          <span>{t("general.login")}</span>
          <ArrowForward />
        </CenterText>
      </Button>
    </>
  );
};

export default LoginOtp1FA;
