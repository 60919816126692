import * as React from "react";
import { BrowserRouter } from "react-router-dom";

import { ContentWrapper, TSOGlobalStyle } from "./App-style";
import AppRoutes from "./AppRoutes";

import {
  BackgroundProvider,
  useBackground,
} from "./Components/Background/Background";
import ErrorBoundary from "./Components/ErrorHandler";
import LoaderComponent from "./Components/LoaderComponent/LoaderComponent";
import Messages, { MessagesProvider } from "./Components/Message/Message";
import { HeaderProvider } from "./Components/SiteHeader/HeaderContext";
import SuspenseBoundary from "./Components/SuspenseBoundary/SuspenseBoundary";
import GAContext from "./Contexts/GAContext";
import NotificationsContext from "./Contexts/NotificationsContext/NotificationsContext";
import SessionContext from "./Contexts/SessionContext";
import useApm from "./hooks/useApm";
import useGtm from "./hooks/useGtm";
import { initCookies } from "./pages/CookiePolicy/CookiePolicy";

declare global {
  interface Window {
    dataLayer: {
      event: string;
      page_path: string;
      page_title: string;
      segment: string;
    }[];
  }
}

const App: React.FC = () => {
  useApm();
  useGtm();
  React.useEffect(() => {
    initCookies();
  }, []);
  return (
    <SuspenseBoundary fallback={<LoaderComponent minHeight="300px" />}>
      <BrowserRouter>
        <GAContext>
          <TSOGlobalStyle />
          <MessagesProvider>
            <BackgroundProvider>
              <ErrorBoundary>
                <SessionContext>
                  <NotificationsContext>
                    <HeaderProvider>
                      <Messages />
                      <SuspenseBoundary>
                        <Content>
                          <AppRoutes />
                        </Content>
                      </SuspenseBoundary>
                    </HeaderProvider>
                  </NotificationsContext>
                </SessionContext>
              </ErrorBoundary>
            </BackgroundProvider>
          </MessagesProvider>
        </GAContext>
      </BrowserRouter>
    </SuspenseBoundary>
  );
};

const Content = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  (props, ref) => {
    const { background } = useBackground();
    return <ContentWrapper ref={ref} {...props} $background={background} />;
  }
);

export default App;
