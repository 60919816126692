import * as React from "react";

export type ContextType = {
  background?: string;
  setBackground: (background?: string) => void;
};

const BackgroundContext = React.createContext<ContextType>({
  background: undefined,
  setBackground: () => null,
});

export const useBackground = (): ContextType => {
  return React.useContext(BackgroundContext);
};

export const BackgroundProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [background, setBackground] = React.useState<string>();
  const contextValue = React.useMemo(() => ({ background, setBackground }), [
    background,
  ]);
  return (
    <BackgroundContext.Provider value={contextValue}>
      {children}
    </BackgroundContext.Provider>
  );
};
