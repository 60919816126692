import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface OperatingTicket {
  id: number;
  severityId: number;
  severityDescription: string;
  statusId: number;
  statusDescription: string;
  arsTicketNumber: string;
  service: string;
  area: string;
  internalInfo: string;
  started: string;
  completed: string | null;
  info: string;
}

export interface LoginMessageNotification {
  id: number;
  translation: string;
  level: string;
}

interface NotificationsModel {
  numberOfOperatingTickets: number;
  operatingTickets: OperatingTicket[];
}

const getNotifications = async () => {
  const { data } = await axios.get<NotificationsModel>("/api/notifications");
  return data;
};

function useNotifications(enabled: boolean) {
  return useQuery<NotificationsModel, AxiosError>({
    queryKey: ["home-notifications"],
    queryFn: getNotifications,
    refetchInterval: 30_000,
    enabled,
  });
}
export default useNotifications;
