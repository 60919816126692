import { color, padding } from "@tele2/t2-ui-kit";
import styled, { createGlobalStyle } from "styled-components";

export const TSOGlobalStyle = createGlobalStyle`
  html {
    min-height: 100vh;   
  }
  body {
    background-color: ${color.DIGITAL_LIGHT_GREY};
    min-height: 100vh;
  }

  #TSOMyPagesRoot {
    min-height: 100vh;
  }
`;

export const ContentWrapper = styled.main`
  min-height: 100vh;
  padding-bottom: ${padding.MD};
  display: flex;
  flex-direction: column;
  ${(props: { $background?: string }) =>
    props.$background ? `background-image: url(${props.$background});` : ""}
  background-size: cover;
  background-position: center;
`;
