export enum MyPagesModules {
  SA_EXTRA_DATA_EU = "SA_EXTRA_DATA_EU",
  SA_ROAMING_DATA_BUCKET = "SA_ROAMING_DATA_BUCKET",
  MY_PAGES_PRIVATE_PROCUREMENT = "MY_PAGES_PRIVATE_PROCUREMENT",
  SA_CALLER_ID = "SA_CALLER_ID",
  SA_CALL_FORWARDING = "SA_CALL_FORWARDING",
  SA_DATA_USAGE = "SA_DATA_USAGE",
  MY_PAGES_MY_INVOICE = "MY_PAGES_MY_INVOICE",
  SA_MEX = "SA_MEX",
  MY_PAGES_MOBILE_QUEUE = "MY_PAGES_MOBILE_QUEUE",
  SA_PERSONAL_QUEUE = "SA_PERSONAL_QUEUE",
  MY_PAGES_SMS_SERVICES = "MY_PAGES_SMS_SERVICES",
  MY_PAGES_SUBSCRIPTION_VIEW = "MY_PAGES_SUBSCRIPTION_VIEW",
  SA_SIM_INFO = "SA_SIM_INFO",
}

export enum MyPagesCapabilities {
  FLEX_CONNECT = "FLEX_CONNECT",
  MI_CLOUD = "MI_CLOUD",
  SEND_SMS = "SEND_SMS",
  ROAMING_DATA_BUCKET = "ROAMING_DATA_BUCKET",
  PERSONAL_QUEUE = "PERSONAL_QUEUE",
  EXTRA_DATA_EU = "EXTRA_DATA_EU",
  DISABLE_VOICEMAIL_ALLOWED = "DISABLE_VOICEMAIL_ALLOWED",
  ACTIVE_MOBILE_SUBSCRIPTION = "ACTIVE_MOBILE_SUBSCRIPTION",
}
