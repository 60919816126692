import { useQueries, useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { MyPagesModules } from "../../hooks/module-types";

const getSubscription = async (subsId: number) => {
  const { data } = await axios.get<SubscriptionProps>(
    `/api/subscriptions/${subsId}`
  );
  return data;
};

const QUERY_KEY = "subscription";
export const useMultiSubscriptions = (subsIds: number[] = []) => {
  return useQueries({
    queries: subsIds.map((subsId) => ({
      queryKey: [QUERY_KEY, subsId],
      queryFn: () => getSubscription(subsId),
    })),
  });
};

const useSubscription = (subsId: number) => {
  return useSuspenseQuery<SubscriptionProps, AxiosError>({
    queryKey: [QUERY_KEY, subsId],
    queryFn: () => getSubscription(subsId),
  });
};

export default useSubscription;

export interface SubscriptionProps {
  subsId: number;
  modules: MyPagesModules[];
  productName: string;
  refreshable: boolean;
}
