import { Button, color, device, Divider, Flex } from "@tele2/t2-ui-kit";
import styled from "styled-components";

export default styled.div`
  position: relative;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 25px;

  @media (min-width: ${device.TABLET}) {
    background: ${color.BRAND_WHITE};
    border: 1px solid ${color.BRAND_GREY};
    min-width: 370px;
    max-width: 370px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  ${Flex} {
    width: 100%;
    margin-top: 16px;
  }

  //span.t2-logo.t2-tele2 {
  //  width: 1.873em;
  //  height: 1em;
  //  background-size: 1.873em 1em;
  //  font-size: 50px;
  //}
`;

export const LoginCardContainer = styled(Flex)<{ $isMobile: boolean }>`
  min-height: ${(props) => (props.$isMobile ? "0" : "50vh")};
`;

export const LoginBackButton = styled(Button)`
  position: absolute !important;
  left: 30px;
  top: 30px;
`;

export const LoginDivider = styled(Divider)`
  width: calc(100% + 50px);
`;
