import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { color, margin } from "@tele2/t2-ui-kit";
import useBreadCrumbs from "../../../util/useBreadCrumbs";
import { useIsMobile } from "@tele2/t2-ui-kit";

const BreadCrumbLink = styled(Link)`
  color: ${color.BRAND_PREMIUM_GREY};
  font-size: 14px;
  margin-right: ${margin.XS};
  text-decoration: none;
  &:hover {
    color: ${color.BRAND_WHITE};
  }

  &:not(:first-child):before {
    content: "/";
    padding-right: ${margin.XS};
  }
`;

const BreadCrumbs: React.FC = () => {
  const breadCrumbs = useBreadCrumbs();
  const isMobile = useIsMobile();

  if (isMobile) return null;

  return (
    <nav>
      {breadCrumbs &&
        breadCrumbs.map((crumb, i) => {
          return (
            <BreadCrumbLink className="breadCrumbs" key={i} to={crumb.url}>
              {crumb.name}
            </BreadCrumbLink>
          );
        })}
    </nav>
  );
};

export default BreadCrumbs;
