import i18n from "i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
      prefix: "{",
      suffix: "}",
    },
    backend: {
      loadPath: `/api/localization/{lng}`,
    },
    parseMissingKeyHandler: (key) => `${key}`,
    supportedLngs: ["en", "sv"],
    detection: {
      caches: [],
      order: ["htmlTag"],
    },
    react: {
      useSuspense: true,
    },
  })
  .catch((res) => console.error(res));

export default i18n;
