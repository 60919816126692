import * as React from "react";
import styled from "styled-components";
import { color } from "@tele2/t2-ui-kit";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { openCookieBanner } from "../../pages/CookiePolicy/CookiePolicy";

const Footer = (): React.ReactElement => {
  return (
    <CookiePolicyWrapper>
      <span onClick={openCookieBanner}>{t("module.cookie.settings")}</span>
      <LinkMarginWrapper>
        <Link to={"/cookiepolicy"}>{t("module.cookie.policy")}</Link>
      </LinkMarginWrapper>
    </CookiePolicyWrapper>
  );
};
export default Footer;

export const CookiePolicyWrapper = styled.div`
  text-align: right;
  padding: 12px 24px;
  span,
  a {
    color: ${color.DIGITAL_DARK_GREY};
    text-decoration: none;
  }
  ,
  span {
    cursor: pointer;
  }
`;
const LinkMarginWrapper = styled.span`
  margin-left: 24px;
`;
