import { useMemo } from "react";
import useSubscriptionsOverview from "../api/subscriptions/useSubscriptionsOverview";
import useSubsId from "../util/useSubsId";
import { MyPagesCapabilities } from "./module-types";

const useCapabilities = (subsId?: number): MyPagesCapabilities[] => {
  const subsIdParam = useSubsId();
  const subsIdToUse = subsId || subsIdParam;
  const { data } = useSubscriptionsOverview(false);
  return useMemo(() => {
    return (
      data?.find((subs) => subs.subsId === subsIdToUse)?.capabilities || []
    );
  }, [data, subsIdToUse]);
};
export default useCapabilities;
