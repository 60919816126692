import styled from "styled-components";

interface CenterTextProps {
  left?: boolean;
}

const CenterText = styled.div<CenterTextProps>`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  :before {
    content: "";
  }
  > svg {
    font-size: 22px;

    &:nth-child(1) {
      grid-column: 1;
      justify-self: flex-start;
    }

    &:nth-child(2) {
      grid-column: 3;
      justify-self: flex-end;
    }
  }
`;

export default CenterText;
