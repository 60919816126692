import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";

type Endpoint = "AUTH" | "API";

export interface Parameters {
  orderRef: string;
  endpoint: Endpoint;
}

const httpCall = async (param: Parameters) => {
  const url =
    param.endpoint === "AUTH"
      ? `/auth/bankid/cancel/${param.orderRef}`
      : `/api/bankid/cancel/${param.orderRef}`;
  await axios.post(url);
};

const useBankIdLoginCancel = () => {
  const { mutateAsync } = useMutation<void, AxiosError, Parameters>({
    mutationFn: httpCall,
    throwOnError: false,
  });

  const cancelBankId = useCallback(
    async (param: Parameters) => {
      try {
        await mutateAsync(param);
      } catch (_) {}
    },
    [mutateAsync]
  );
  return { cancelBankId };
};

export default useBankIdLoginCancel;
