import { i18n } from "i18next";

export function formatDate(
  { language }: i18n,
  value: string | number | Date
): string {
  if (!value) {
    return "";
  }
  const date: Date =
    typeof value === "string" || typeof value === "number"
      ? new Date(value)
      : value;

  return new Intl.DateTimeFormat(language, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(date);
}

export function formatMonthYear(
  { language }: i18n,
  value: string | number | Date
): string {
  if (!value) {
    return "";
  }
  const date: Date =
    typeof value === "string" || typeof value === "number"
      ? new Date(value)
      : value;

  return new Intl.DateTimeFormat(language, {
    month: "long",
    year: "numeric",
  }).format(date);
}

export const formatDateTime = (
  { language }: i18n,
  value: string | number | Date
): string => {
  if (!value) {
    return "";
  }
  const date: Date =
    typeof value === "string" || typeof value === "number"
      ? new Date(value)
      : value;

  return new Intl.DateTimeFormat(language, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(date);
};

export const secondsToMinutesAndSeconds = (seconds: number): string => {
  const rest = seconds % 60;
  return `${(seconds - rest) / 60}:${rest > 9 ? "" : 0}${rest}`;
};

export const formatAmount = (
  amount: number | string,
  { language }: i18n,
  currency?: string
): string => {
  if (typeof amount === "string") {
    amount = Number(amount.replace(",", "."));
  }
  return amount.toLocaleString(language, {
    style: "currency",
    currency: currency || "SEK",
  });
};

export const getEnv = (): string => {
  if (window.location.origin.includes("dev")) {
    return "dev";
  }
  if (window.location.origin.includes("stage")) {
    return "stage";
  }
  if (
    window.location.origin.includes("local") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    return "local";
  }
  if (window.location.origin.includes("test")) {
    return "test";
  }
  return "prod";
};
