import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface OtpModel {
  otpSmsNumber: string;
  timeout: number;
  message: string;
  error: string;
}

const otpLogin = async (phoneNumber: string) => {
  const { data } = await axios.post("/auth/login-otp-method", null, {
    params: {
      phoneNumber,
    },
  });
  return data;
};

const useOtpMethod = () => {
  return useMutation<OtpModel, AxiosError, string>({ mutationFn: otpLogin });
};

export default useOtpMethod;
