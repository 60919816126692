import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export type TokenType = "AUTO_START" | "QR_CODE";
export type Endpoint = "AUTH" | "API";

export interface Parameters {
  tokenType: TokenType;
  endpoint: Endpoint;
}

export interface BankIdAuthModel {
  orderRef: string;
  autoStartToken: string;
}

const httpCall = async (param: Parameters) => {
  const url = param.endpoint === "AUTH" ? "/auth/bankid" : "/api/bankid/auth";
  const { data } = await axios.post<BankIdAuthModel>(url, {
    tokenType: param.tokenType,
  });
  return data;
};

const useBankIdLoginAuth = () => {
  return useMutation<BankIdAuthModel, AxiosError, Parameters>({
    mutationFn: httpCall,
  });
};

export default useBankIdLoginAuth;
