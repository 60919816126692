import styled from "styled-components";

import { margin } from "@tele2/t2-ui-kit";

export const MessagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 64px;
  z-index: 1001;
  > div {
    margin-bottom: ${margin.XXS};
  }
`;
