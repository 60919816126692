import { Button, color, Flex, H2, Logo } from "@tele2/t2-ui-kit";
import ArrowBack from "@tele2/t2-ui-kit/build/Icon/icons/ArrowBack";
import ArrowForward from "@tele2/t2-ui-kit/build/Icon/icons/ArrowForward";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useBackground } from "../../Components/Background/Background";
import CenterText from "../../Components/CenterText/CenterText";
import { useGATracking } from "../../Contexts/GAContext";
import photo from "../../img/background-login.jpg";
import { useIsMobile } from "../../util/useWindowSize";
import { openCookieBanner } from "../CookiePolicy/CookiePolicy";
import LoginBankId from "./LoginBankId";

import LoginCard, {
  LoginBackButton,
  LoginCardContainer,
  LoginDivider,
} from "./LoginCard";
import LoginLanguageSelector from "./LoginLanguageSelector";
import LoginOtp from "./LoginOtp";

type LoginState = "otp" | "bank-id";

const Login: React.FC = () => {
  const [state, setState] = React.useState<LoginState | void>();
  const isMobile = useIsMobile();
  const { setBackground } = useBackground();
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = t("tso.my.pages");
  }, [t]);
  React.useEffect(() => {
    setBackground(isMobile ? undefined : photo);
    return () => {
      setBackground();
    };
  }, [isMobile, setBackground]);
  useGATracking(t("tso.my.pages"));
  return (
    <LoginCardContainer
      alignItems="center"
      justifyContent="center"
      $isMobile={isMobile}
    >
      <LoginCard>
        <Logo marginBottom="MD" variant="tele2" size={100} />
        {state ? (
          <LoginBackButton
            round
            ghost
            icon={<ArrowBack />}
            onClick={() => setState()}
          />
        ) : (
          <H2 marginBottom="xl">{t("module.login.title")}</H2>
        )}

        {(() => {
          switch (state) {
            case "otp":
              return <LoginOtp />;
            case "bank-id":
              return <LoginBankId endpoint={"AUTH"} />;
            default:
              return (
                <>
                  <Button
                    block
                    marginBottom="md"
                    onClick={() => setState("bank-id")}
                  >
                    <CenterText>
                      <span>{t("module.login.bankId")}</span>
                      <ArrowForward />
                    </CenterText>
                  </Button>

                  <Button
                    block
                    secondary
                    marginBottom="md"
                    onClick={() => setState("otp")}
                  >
                    <CenterText>
                      <span>{t("module.login.otp")}</span>
                      <ArrowForward />
                    </CenterText>
                  </Button>
                </>
              );
          }
        })()}
        <LoginDivider />
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <LoginLanguageSelector />
          <CookieLinksWrapper>
            <span onClick={openCookieBanner}>
              {t("module.cookie.settings")}
            </span>
            <Link to={"cookiepolicy"} title={t("module.cookie.policy")}>
              {t("module.cookie.policy")}
            </Link>
          </CookieLinksWrapper>
        </Flex>
      </LoginCard>
    </LoginCardContainer>
  );
};

export default Login;
const CookieLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 40px;
  a,
  span {
    font-size: 13px;
    color: ${color.FUNCTIONAL_BLUE};
    text-decoration: none;
  }
  ,
  span {
    cursor: pointer;
  }
`;
