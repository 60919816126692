import { init as initApm } from "@elastic/apm-rum";
import { useEffect, useState } from "react";
import useAcceptStatistics from "../pages/CookiePolicy/useAcceptStatistics";
import { getEnv } from "../util/utils";

export const useApm = (): void => {
  const acceptStatistics = useAcceptStatistics();
  const [, setPrevAccepted] = useState<boolean>(acceptStatistics);
  useEffect(() => {
    if (acceptStatistics) {
      const environment = getEnv();
      const serverUrl = "https://proxy-tso.pex.ymer.tele2.com/";
      initApm({
        active: "dev,stage,prod".includes(environment),
        serviceName: "tso-my-pages-ui",
        serverUrl: serverUrl,
        environment: environment,
      });
    }
    setPrevAccepted((prevValue) => {
      if (acceptStatistics !== prevValue) {
        window.location.reload();
      }
      return acceptStatistics;
    });
  }, [acceptStatistics]);
};
export default useApm;
