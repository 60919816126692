import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useCallback } from "react";

const useLogout = () => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation<unknown, AxiosError, void>({
    mutationFn: () => axios.get<void>(`/logout`),
    throwOnError: true,
  });

  const logout = useCallback(async () => {
    await mutateAsync();
    // To prevent queries to run again
    queryClient.removeQueries();
    window.location.href = "/";
  }, [mutateAsync, queryClient]);

  return { logout };
};
export default useLogout;
