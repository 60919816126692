import { breakpoints, Device } from "@tele2/t2-ui-kit";
import * as React from "react";

// to-do: get these constants from core-lib
export const tabletWidth: number = breakpoints.Tablet;
export const desktopWidth: number = breakpoints.Desktop;

export const useWindowSize = (): { height: number; width: number } => {
  const [size, setSize] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    const handleResize = () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
};

export const useWindowHeight = (): number => {
  const { height } = useWindowSize();
  return height;
};

export const useWindowWidth = (): number => {
  const { width } = useWindowSize();
  return width;
};

export const useIsMobile = (): boolean => {
  const width = useWindowWidth();
  return width < tabletWidth;
};

export const useIsTablet = (): boolean => {
  const width = useWindowWidth();
  return width >= tabletWidth && width < desktopWidth;
};

export const useIsDesktop = (): boolean => {
  const width = useWindowWidth();
  return width >= desktopWidth;
};

export const useIsDevice = (): Device => {
  const width = useWindowWidth();
  if (width >= desktopWidth) {
    return Device.Desktop;
  } else if (width >= tabletWidth) {
    return Device.Tablet;
  } else {
    return Device.Mobile;
  }
};
