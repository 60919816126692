import "@tele2/t2-ui-kit/build/global.css";
import { QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import Qs from "qs";
import * as React from "react";
import { createRoot } from "react-dom/client";
import "./i18n.ts";

import App from "./App";
import Suspended from "./pages/Suspended/Suspended";
import queryClient from "./queryClient";

const container = document.querySelector("#TSOMyPagesRoot") as HTMLElement;

const root = createRoot(container);

// Be compatible with servlet spec
//https://github.com/spring-projects/spring-boot/issues/14487
axios.defaults.paramsSerializer = (params) =>
  Qs.stringify(params, { arrayFormat: "repeat" });

if (window.location.pathname.endsWith("/suspended")) {
  root.render(<Suspended />);
} else {
  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
}
