import * as React from "react";
import usePrincipalModules from "../api/subscriptions/usePrincipalModules";
import useSubscription, {
  SubscriptionProps,
  useMultiSubscriptions,
} from "../api/subscriptions/useSubscription";
import { SubscriptionModel } from "../api/subscriptions/useSubscriptionsOverview";
import { SessionContext } from "../Contexts/SessionContext";
import useSubsId from "../util/useSubsId";
import { MyPagesCapabilities, MyPagesModules } from "./module-types";
import useCapabilities from "./useCapabilities";

export interface ModulesProps {
  callerId?: boolean;
  callForwarding?: boolean;
  costs?: boolean;
  dataUsage?: boolean;
  disconnectSubscription?: boolean;
  mex?: boolean;
  mobileQueue?: boolean;
  personalQueue?: boolean;
  privatePurchases?: boolean;
  refresh?: boolean;
  simInfo?: boolean;
  smsTool?: boolean;
  subscriptionDetails?: boolean;
  //TODO: uncomment to feature toggle VoiceMail:
  //voiceMail?: boolean;
}

interface UseModulesProps {
  data?: ModulesProps;
}

function getModulePermissions(
  capabilities: MyPagesCapabilities[],
  principalModules: MyPagesModules[],
  isMobile: boolean,
  subscription?: SubscriptionProps,
  bankIdAuth?: boolean
): ModulesProps {
  const subsMods = subscription?.modules || [];
  const hasExtraDataEU =
    capabilities?.indexOf(MyPagesCapabilities.EXTRA_DATA_EU) !== -1 &&
    principalModules?.indexOf(MyPagesModules.SA_EXTRA_DATA_EU) !== -1;
  const hasRoamingData =
    capabilities?.indexOf(MyPagesCapabilities.ROAMING_DATA_BUCKET) !== -1 &&
    principalModules?.indexOf(MyPagesModules.SA_ROAMING_DATA_BUCKET) !== -1;

  // The order affects the order they are rendered
  return {
    refresh: subscription?.refreshable || false,
    privatePurchases: isMobile
      ? principalModules?.indexOf(
          MyPagesModules.MY_PAGES_PRIVATE_PROCUREMENT
        ) !== -1 && bankIdAuth === true
      : principalModules?.indexOf(
          MyPagesModules.MY_PAGES_PRIVATE_PROCUREMENT
        ) !== -1,
    callerId:
      subsMods.indexOf(MyPagesModules.SA_CALLER_ID) !== -1 &&
      principalModules?.indexOf(MyPagesModules.SA_CALLER_ID) !== -1,
    callForwarding:
      subsMods.indexOf(MyPagesModules.SA_CALL_FORWARDING) !== -1 &&
      principalModules?.indexOf(MyPagesModules.SA_CALL_FORWARDING) !== -1,
    dataUsage:
      subsMods.indexOf(MyPagesModules.SA_DATA_USAGE) !== -1 &&
      principalModules?.indexOf(MyPagesModules.SA_DATA_USAGE) !== -1,
    costs:
      principalModules?.indexOf(MyPagesModules.MY_PAGES_MY_INVOICE) !== -1 ||
      hasRoamingData ||
      hasExtraDataEU,
    mex:
      subsMods.indexOf(MyPagesModules.SA_MEX) !== -1 &&
      principalModules?.indexOf(MyPagesModules.SA_MEX) !== -1,
    mobileQueue:
      subsMods.indexOf(MyPagesModules.MY_PAGES_MOBILE_QUEUE) !== -1 &&
      principalModules?.indexOf(MyPagesModules.MY_PAGES_MOBILE_QUEUE) !== -1,
    personalQueue:
      subsMods.indexOf(MyPagesModules.SA_PERSONAL_QUEUE) !== -1 &&
      principalModules?.indexOf(MyPagesModules.SA_PERSONAL_QUEUE) !== -1,
    simInfo:
      subsMods.indexOf(MyPagesModules.SA_SIM_INFO) !== -1 &&
      principalModules?.indexOf(MyPagesModules.SA_SIM_INFO) !== -1,
    smsTool:
      capabilities?.indexOf(MyPagesCapabilities.SEND_SMS) !== -1 &&
      principalModules?.indexOf(MyPagesModules.MY_PAGES_SMS_SERVICES) !== -1,
    subscriptionDetails:
      principalModules?.indexOf(MyPagesModules.MY_PAGES_SUBSCRIPTION_VIEW) !==
      -1,
    //TODO: uncomment to feature toggle VoiceMail:
    //voiceMail: capabilities?.indexOf(MyPagesCapabilities.DISABLE_VOICEMAIL_ALLOWED) !== -1,
    disconnectSubscription: bankIdAuth,
  };
}

export const useModulesPerSubsIds = (
  subscriptions: SubscriptionModel[]
): ModulesProps[] => {
  const subsArray = useMultiSubscriptions(
    subscriptions.map(({ subsId }) => subsId)
  );
  const { data: principalModules } = usePrincipalModules();
  const { user } = React.useContext(SessionContext);
  return subsArray.map(({ data: subscription }, i) => {
    return getModulePermissions(
      subscriptions[i].capabilities,
      principalModules || [],
      true,
      subscription,
      user?.bankIdAuth
    );
  });
};

const useModules = (): UseModulesProps => {
  const subsId = useSubsId();
  const capabilities = useCapabilities(subsId);
  const { data: principalModules } = usePrincipalModules();
  const { data: subscription } = useSubscription(subsId);
  const { user } = React.useContext(SessionContext);

  return {
    data: getModulePermissions(
      capabilities,
      principalModules || [],
      false,
      subscription,
      user?.bankIdAuth
    ),
  };
};

export default useModules;
