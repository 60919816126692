import * as React from "react";

import { MessagesContainer } from "./message-styles";
import { ContextType, MessageType } from "./message-types";
import MessageItem from "./MessageItem";

const MessagesContext = React.createContext<ContextType>({
  messageQueue: [],
  setMessageQueue: () => null,
});

let id = 0;
export const useMessage = (): ((m: MessageType) => void) => {
  const { setMessageQueue } = React.useContext(MessagesContext);
  return React.useCallback(
    (m: MessageType) => setMessageQueue((old) => [...old, { id: ++id, ...m }]),
    [setMessageQueue]
  );
};

export const MessagesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [messageQueue, setMessageQueue] = React.useState<MessageType[]>([]);
  const contextValue = React.useMemo(
    () => ({ messageQueue, setMessageQueue }),
    [messageQueue]
  );
  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

const Message: React.FC = () => {
  const { messageQueue, setMessageQueue } = React.useContext(MessagesContext);
  return (
    <MessagesContainer>
      {messageQueue.map((m: MessageType) => (
        <MessageItem key={m.id} setMessageQueue={setMessageQueue} {...m} />
      ))}
    </MessagesContainer>
  );
};
export default Message;
