import { Button, H2 } from "@tele2/t2-ui-kit";
import ArrowForward from "@tele2/t2-ui-kit/build/Icon/icons/ArrowForward";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useBankIdLoginAuth, {
  Endpoint,
  TokenType,
} from "../../api/bankid/useBankIdLoginAuth";

import CenterText from "../../Components/CenterText/CenterText";
import LoaderComponent from "../../Components/LoaderComponent/LoaderComponent";
import { useIsMobile } from "../../util/useWindowSize";
import LoginBankIdCollect from "./LoginBankIdCollect";

interface Props {
  endpoint: Endpoint;
}

interface State {
  orderRef: string;
  autoStartToken: string;
  tokenType?: TokenType;
}

const initState: State = {
  orderRef: "",
  autoStartToken: "",
};

const LoginBankId: React.FC<Props> = ({ endpoint }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [state, setState] = React.useState<State>(initState);
  const { mutateAsync: startBankIdAuth, isPending } = useBankIdLoginAuth();
  const clear = React.useCallback(() => setState(initState), []);
  return (
    <>
      {state.orderRef && state.tokenType ? (
        <LoginBankIdCollect
          tokenType={state.tokenType}
          autoStartToken={state.autoStartToken}
          orderRef={state.orderRef}
          clear={clear}
          endpoint={endpoint}
        />
      ) : (
        <>
          <H2 marginBottom="xl">{t("module.login.bankIdTitle")}</H2>
          <Button
            block
            secondary={!isMobile}
            marginBottom="md"
            onClick={async () => {
              setState((prevState) => ({
                ...prevState,
                tokenType: "AUTO_START",
              }));
              const bankIdAuthModel = await startBankIdAuth({
                tokenType: "AUTO_START",
                endpoint,
              });
              setState((prevState) => ({
                ...prevState,
                ...bankIdAuthModel,
                tokenType: "AUTO_START",
              }));
            }}
            disabled={isPending}
          >
            <CenterText>
              {isPending && state.tokenType === "AUTO_START" ? (
                <LoaderComponent />
              ) : (
                <>
                  <span>{t("module.login.bankIdOpenOnThisDevice")}</span>
                  <ArrowForward />
                </>
              )}
            </CenterText>
          </Button>
          <Button
            block
            secondary={isMobile}
            marginBottom="md"
            onClick={async () => {
              setState((prevState) => ({
                ...prevState,
                tokenType: "QR_CODE",
              }));
              const bankIdAuthModel = await startBankIdAuth({
                tokenType: "QR_CODE",
                endpoint,
              });
              setState((prevState) => ({
                ...prevState,
                ...bankIdAuthModel,
                tokenType: "QR_CODE",
              }));
            }}
            disabled={isPending}
          >
            <CenterText>
              {isPending && state.tokenType === "QR_CODE" ? (
                <LoaderComponent />
              ) : (
                <>
                  <span>{t("module.login.bankIdOpenOnOtherDevice")}</span>
                  <ArrowForward />
                </>
              )}
            </CenterText>
          </Button>
        </>
      )}
    </>
  );
};

export default LoginBankId;
