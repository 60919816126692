import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useSelectedSubscription from "../hooks/useSelectedSubscription";
import { routes } from "../AppRoutes";
import useSubsId from "./useSubsId";

interface BreadCrumb {
  name: string;
  url: string;
}

const useBreadCrumbs = (): BreadCrumb[] => {
  const subsId = useSubsId();
  const location = useLocation();
  const { t } = useTranslation();
  const currentSubscription = useSelectedSubscription();
  return React.useMemo(() => {
    const routesToSort = routes.filter(
      (r) =>
        !r.breadCrumbIgnore &&
        r.title &&
        location.pathname.includes(r.path.replace(":subsId", String(subsId))) &&
        r.path.replace(":subsId", String(subsId)) !== location.pathname
    );

    if (routesToSort && routesToSort.length > 1) {
      routesToSort.sort((o1, o2) => {
        if (!o1.path || !o2.path) {
          return 0;
        }
        return o1.path.length < o2.path.length ? -1 : 1;
      });
    }
    return routesToSort.map((route) => {
      if (route.isSubscription && currentSubscription) {
        return {
          name: currentSubscription.phoneNumber
            ? currentSubscription.phoneNumber
            : currentSubscription.name,
          url: route.path ? route.path?.replace(":subsId", String(subsId)) : "",
        };
      }

      return {
        name: route.title ? t(route.title) : "",
        url: route.path ? route.path?.replace(":subsId", String(subsId)) : "",
      };
    });
  }, [currentSubscription, location, subsId, t]);
};

export default useBreadCrumbs;
