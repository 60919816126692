import { Button, Text, TextInput } from "@tele2/t2-ui-kit";
import ArrowForward from "@tele2/t2-ui-kit/build/Icon/icons/ArrowForward";
import * as React from "react";
import { useTranslation } from "react-i18next";

import CenterText from "../../Components/CenterText/CenterText";
import onEnter from "../../util/onEnter";

const isPhoneNumber = (phoneNumber: string) => phoneNumber.length > 5;

const LoginInitOtp: React.FC<{ onSubmit: (phoneNumber: string) => void }> = (
  props
) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  return (
    <>
      <Text xl>{t("module.login.enterPhoneNumber")}</Text>
      <TextInput
        block
        marginTop="md"
        marginBottom="md"
        label={t("module.login.username")}
        value={phoneNumber}
        onKeyDown={onEnter(
          () => isPhoneNumber(phoneNumber) && props.onSubmit(phoneNumber)
        )}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPhoneNumber(e.target.value)
        }
        autoComplete="tel"
      />
      <Button
        block
        marginBottom="md"
        onClick={() => props.onSubmit(phoneNumber)}
        disabled={!isPhoneNumber(phoneNumber)}
      >
        <CenterText>
          <span>{t("module.login.requestOtp")}</span>
          <ArrowForward />
        </CenterText>
      </Button>
    </>
  );
};

export default LoginInitOtp;
