import { Header, useIsMobile, useWindowScrollPosition } from "@tele2/t2-ui-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";

import useSubscriptionsOverview from "../../api/subscriptions/useSubscriptionsOverview";
import useCanOrder from "../../hooks/useCanOrder";
import { ModulesProps, useModulesPerSubsIds } from "../../hooks/useModules";

import { paths, routes } from "../../AppRoutes";
import MainMenuButton from "./components/MainMenuButton";

import { HeaderContext } from "./HeaderContext";
import NotificationButton from "./NotificationButton";
import UserMenu from "./UserMenu";

interface MainMenuItemProps {
  isRoot?: boolean;
  subItems?: MainMenuItemProps[];
  title: string;
  url?: string;
}

const SiteHeader: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const windowScrollPos = useWindowScrollPosition();

  const isRefreshable = Boolean(useMatch(paths.REFRESHABLE_SUBSCRIPTIONS));
  const { data: subscriptionList = [] } = useSubscriptionsOverview(
    isRefreshable
  );
  const subsPermissions = useModulesPerSubsIds(subscriptionList);

  const onMainMenuChange = React.useCallback(
    (menuItem: MainMenuItemProps) => {
      menuItem.url && navigate(menuItem.url);
    },
    [navigate]
  );

  const canAccessOrder = useCanOrder();

  const subscriptionMenuItems = React.useMemo(
    () =>
      subscriptionList.map(({ subsId, name, phoneNumber }, i) => {
        const subsSubItems = Object.keys(subsPermissions[i]).reduce(
          (acc: MainMenuItemProps[], moduleKey: string) => {
            const renderModule =
              subsPermissions[i][moduleKey as keyof ModulesProps];
            const definedModule = routes.find(({ id }) => id === moduleKey);
            if (renderModule && definedModule && !!definedModule.path) {
              const subsIdPath = generatePath(definedModule.path, {
                subsId: subsId,
              });
              acc.push({
                title: definedModule.title
                  ? t(definedModule.title)
                  : t("general.missing"),
                url: subsIdPath,
              });
            }
            return acc;
          },
          []
        );

        return {
          title: phoneNumber ? ` (${phoneNumber}) ${name}` : name,
          url: `/subscription/${subsId}`,
          subItems: subsSubItems,
        };
      }),
    [subsPermissions, subscriptionList, t]
  );

  const mainMenuItems: MainMenuItemProps[] = React.useMemo(() => {
    return [
      {
        title: t("general.subscriptions"),
        url: "/",
        ...(isMobile && {
          subItems: subscriptionMenuItems,
        }),
      },
      ...((canAccessOrder && [
        {
          title: t("general.order"),
          url: paths.ORDER,
        },
      ]) ||
        []),
    ];
  }, [canAccessOrder, isMobile, subscriptionMenuItems, t]);

  const useGradientBackground =
    location.pathname === "/" && windowScrollPos === 0;

  const { header } = React.useContext(HeaderContext);
  return (
    <Header
      {...header}
      mainMenu={mainMenuItems}
      mobileHeaderLeft={
        <MainMenuButton
          onMainMenuChange={onMainMenuChange}
          menuItems={mainMenuItems}
        />
      }
      logoLink="/"
      onLogoLinkClick={(e) => {
        e.preventDefault();
        navigate("/");
      }}
      onMainMenuChange={onMainMenuChange}
      solidBackground={!useGradientBackground}
    >
      <NotificationButton />
      <UserMenu />
    </Header>
  );
};

export default SiteHeader;
