import * as React from "react";
import styled from "styled-components";
import NotificationCard from "./NotificationCard";
import { NotificationsViewState } from "../../../Contexts/NotificationsContext/notification-utils";
import { useAppNotifications } from "../../../Contexts/NotificationsContext/NotificationsContext";
import { device } from "@tele2/t2-ui-kit";

const NewNotificationsPopup: React.FC = () => {
  const { setNotificationsViewState, newNotifications } = useAppNotifications();
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setNotificationsViewState(NotificationsViewState.NONE);
    }, 6_000);
    return () => clearTimeout(handler);
  }, [setNotificationsViewState]);
  return (
    <NotificationsContainer>
      {newNotifications &&
        newNotifications
          .slice(0, 5)
          .map((cardProps, i) => (
            <NotificationCard {...cardProps} key={i} delay={i} />
          ))}
    </NotificationsContainer>
  );
};
export default NewNotificationsPopup;

const NotificationsContainer = styled.div`
  position: absolute;
  right: 0;
  margin: 5px;
  width: 420px;
  @media (max-width: ${device.TABLET}) {
    width: 90vw;
    right: -30px;
  }
`;
