import { Button, Flex } from "@tele2/t2-ui-kit";
import Launch from "@tele2/t2-ui-kit/build/Icon/icons/LaunchOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getEnv } from "../../util/utils";
import LoginLanguageSelector from "../Login/LoginLanguageSelector";

declare global {
  interface Window {
    OneTrust: {
      OnConsentChanged: (e: () => void) => void;
      ToggleInfoDisplay: () => void;
      initializeCookiePolicyHtml: () => void;
      changeLanguage: (language: string) => void;
    };
    OptanonWrapper: () => void;
  }
}

export const initCookies = () => {
  const addListener = () => {
    if (window.OneTrust) {
      try {
        window.OneTrust.OnConsentChanged(() => {
          Object.entries(callbacks).forEach((c) => c[1]());
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      setTimeout(addListener, 250);
    }
  };
  const env = getEnv();
  if (["local", "test", "dev", "stage", "prod"].includes(env)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.OptanonWrapper = () => {};
    const script = document.createElement("script");
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.type = "text/javascript";
    script.dataset.domainScript = `02cd8f5e-85fe-43e2-aea9-8f907c5d0633${
      ["local", "test", "dev", "stage"].includes(env) ? "-test" : ""
    }`;
    script.dataset.language = (
      document.documentElement.lang ||
      navigator.language ||
      "en"
    ).substring(0, 2);
    script.onload = addListener;
    document.body.appendChild(script);
  }
};

const cookieContains = (value: string): boolean => {
  const regex = /OptanonConsent=([^;]+)/;
  const matches = regex.exec(document.cookie);
  const cookieValue = matches !== null ? decodeURI(matches[1]) : "false";
  return cookieValue.includes(value);
};

const callbacks: { [key: number]: () => void } = {};

export const useCookieWatcher = (name: string): boolean => {
  const [state, setState] = React.useState<boolean>(cookieContains(name));
  React.useEffect(() => {
    const rand = Math.floor(Math.random() * 1000000);
    callbacks[rand] = () => setState(cookieContains(name));
    return () => {
      delete callbacks[rand];
    };
  }, [name]);
  return state;
};

export const openCookieBanner = () => {
  try {
    window.OneTrust.ToggleInfoDisplay();
  } catch (e) {
    console.error(e);
  }
};
export const initCookiePolicy = () =>
  window.OneTrust.initializeCookiePolicyHtml();

const CookiePolicy: React.FC = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    try {
      if (window.OneTrust) {
        initCookiePolicy();
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <HeaderRowWrapper>
        <Button secondary onClick={() => navigate(-1)}>
          {t("general.back")}
        </Button>
        <LanguageSelectorWrapper>
          <LoginLanguageSelector />
        </LanguageSelectorWrapper>
      </HeaderRowWrapper>
      <HeaderWrapper>
        <h1>{t("module.cookie.policy")}</h1>
      </HeaderWrapper>
      <p
        dangerouslySetInnerHTML={{
          __html: t("module.cookie.ingress"),
        }}
      />
      <Flex alignItems={"center"} justifyContent={"center"}>
        <Button onClick={openCookieBanner} data-testid="cookie-policy-settings">
          {t("module.cookie.settings")}
          <Launch sm marginLeft={"xs"} />
        </Button>
      </Flex>
      <div id="ot-sdk-cookie-policy" />
    </PageWrapper>
  );
};

export default CookiePolicy;
const HeaderWrapper = styled.div`
  text-align: center;
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LanguageSelectorWrapper = styled.div`
  .t2-dropdown {
    width: 200px;
  }
`;

const PageWrapper = styled.div`
  margin: 24px auto;
  border-radius: 4px;
  max-width: 960px;
  padding: 24px;
  background-color: #ffffff;
`;
