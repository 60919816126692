import { useMemo } from "react";
import useSubscriptionsOverview, {
  SubscriptionModel,
} from "../api/subscriptions/useSubscriptionsOverview";
import { MyPagesCapabilities } from "./module-types";

const useActiveMobileSubscriptions = (): SubscriptionModel[] => {
  const { data: subscriptions } = useSubscriptionsOverview(false);
  return useMemo(() => {
    return subscriptions.filter((subs) =>
      subs.capabilities.includes(MyPagesCapabilities.ACTIVE_MOBILE_SUBSCRIPTION)
    );
  }, [subscriptions]);
};
export default useActiveMobileSubscriptions;
