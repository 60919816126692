import * as React from "react";
import { useTranslation } from "react-i18next";

const Suspended = (): React.JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="error-top-margin m-3">
        <div className="alert alert-danger" role="alert">
          <h2>{t("module.suspension.header")}</h2>
          <p>{t("module.suspension.message")}</p>
        </div>
      </div>
    </div>
  );
};

export default Suspended;
