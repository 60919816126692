import { HeaderButton } from "@tele2/t2-ui-kit";
import NotificationIcon from "@tele2/t2-ui-kit/build/Icon/icons/NotificationsOutlined";

import * as React from "react";
import styled from "styled-components";
import { NotificationsViewState } from "../../Contexts/NotificationsContext/notification-utils";
import { useAppNotifications } from "../../Contexts/NotificationsContext/NotificationsContext";
import NewNotificationsPopup from "./Notifications/NewNotificationsPopup";
import { useTranslation } from "react-i18next";
import NotificationsPopup from "./Notifications/NotificationsPopup";
import { SessionContext } from "../../Contexts/SessionContext";

const NotificationButton: React.FC = () => {
  const {
    notReadNotifications,
    notificationsViewState,
    setNotificationsViewState,
  } = useAppNotifications();
  const { t } = useTranslation();
  const { authenticated, valid } = React.useContext(SessionContext);
  if (!authenticated || !valid) {
    return null;
  }
  return (
    <ButtonWrapper>
      <HeaderButton
        role={"button"}
        aria-label={t("module.self.service.recent.activities") ?? ""}
        notification={
          notReadNotifications && notReadNotifications.length > 0
            ? String(notReadNotifications.length)
            : undefined
        }
        onClick={() => {
          setNotificationsViewState(
            toggleNotificationsPopup(
              notificationsViewState,
              notReadNotifications?.length || 0
            )
          );
        }}
      >
        <NotificationIcon />
      </HeaderButton>
      {(notificationsViewState === NotificationsViewState.ALL ||
        notificationsViewState === NotificationsViewState.NOT_READ) && (
        <NotificationsPopup />
      )}
      {notificationsViewState === NotificationsViewState.NEW && (
        <NewNotificationsPopup />
      )}
    </ButtonWrapper>
  );
};
export default NotificationButton;

const ButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const toggleNotificationsPopup = (
  notificationsViewState: NotificationsViewState,
  unreadNotifications: number
): NotificationsViewState => {
  if (notificationsViewState !== NotificationsViewState.NONE) {
    return NotificationsViewState.NONE;
  }
  return unreadNotifications > 0
    ? NotificationsViewState.NOT_READ
    : NotificationsViewState.ALL;
};
