import * as React from "react";

const onEnter = (cb: () => void) => (
  e: React.KeyboardEvent<HTMLInputElement>
): void => {
  if (e.key === "Enter") {
    cb();
  }
};

export default onEnter;
