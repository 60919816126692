import * as React from "react";
import { useTranslation } from "react-i18next";
import useBankIdLoginQr from "../../api/bankid/useBankIdLoginQr";
import LoaderComponent from "../../Components/LoaderComponent/LoaderComponent";
import { useMessage } from "../../Components/Message/Message";

type Endpoint = "AUTH" | "API";
const LIMIT = 30;

interface Props {
  orderRef: string;
  endpoint: Endpoint;
  onCancel: () => void;
}

interface State {
  count: number;
}

const initState: State = {
  count: 0,
};

const LoginBankIdQr: React.FC<Props> = ({ orderRef, endpoint, onCancel }) => {
  const message = useMessage();
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>(initState);
  const { data, isLoading, refetch, isError } = useBankIdLoginQr({
    orderRef,
    endpoint,
  });

  React.useEffect(() => {
    if (isError) {
      message({
        interval: 5000,
        message: t("module.login.bankIdTimeoutError"),
        theme: "alert",
      });
      setState({ count: LIMIT + 1 });
      onCancel();
    }
  }, [isError, message, onCancel, t]);

  React.useEffect(() => {
    if (state.count >= LIMIT) {
      return;
    }

    if (orderRef !== "") {
      const timeout = setTimeout(async () => {
        await refetch();
        setState((ps) => ({ ...ps, count: state.count + 1 }));
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [state, refetch, orderRef]);
  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <img src={`data:image/png;base64,${data?.image}`} alt="qr-code" />
          <div
            dangerouslySetInnerHTML={{
              __html: t("module.login.bankIdBodyQr"),
            }}
          />
        </>
      )}
    </>
  );
};

export default LoginBankIdQr;
