import { useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { MyPagesCapabilities } from "../../hooks/module-types";

export interface SubscriptionModel {
  subsId: number;
  name: string;
  phoneNumber: string;
  nameUserFunction: string;
  capabilities: MyPagesCapabilities[];
}

const getSubscriptionOverview = async (refreshableOnly: boolean) => {
  const { data } = await axios.get<SubscriptionModel[]>("/api/subscriptions", {
    params: {
      refreshableOnly,
    },
  });
  return data || [];
};

export const QUERY_KEY = "subscription-overview";

function useSubscriptionsOverview(refreshableOnly: boolean) {
  return useSuspenseQuery<SubscriptionModel[], AxiosError>({
    queryKey: [QUERY_KEY, refreshableOnly],
    queryFn: () => getSubscriptionOverview(refreshableOnly),
    staleTime: Infinity,
  });
}

export default useSubscriptionsOverview;
