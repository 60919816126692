import { Loader } from "@tele2/t2-ui-kit";
import * as React from "react";
import styled from "styled-components";

interface LoaderProps {
  text?: string;
  minHeight?: string;
  minWidth?: string;
  flexGrow?: number;
}

const LoaderComponent: React.FC<LoaderProps> = ({
  text,
  minHeight,
  minWidth,
  flexGrow,
}) => {
  return (
    <LoaderContainer
      $minHeight={minHeight}
      $minWidth={minWidth}
      $flexGrow={flexGrow}
    >
      <Loader loadingText={text} textPosition={"bottom"} />
    </LoaderContainer>
  );
};

export default LoaderComponent;

const LoaderContainer = styled.div<{
  $minHeight?: string;
  $minWidth?: string;
  $flexGrow?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => (props.$minWidth ? `min-width:${props.$minWidth};` : "")}
  ${(props) => (props.$minHeight ? `min-height:${props.$minHeight};` : "")}
  ${(props) => (props.$flexGrow ? `flex-grow:${props.$flexGrow};` : "")}
`;
