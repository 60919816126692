import * as React from "react";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../LoaderComponent/LoaderComponent";
import Login from "../../pages/Login/Login";
import { SessionContext } from "../../Contexts/SessionContext";

interface AuthenticatedRouteProps {
  element: React.ReactElement;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ element }) => {
  const { authenticated, valid } = React.useContext(SessionContext);
  const { t } = useTranslation();
  if (!authenticated) {
    return <Login />;
  }
  if (!valid) {
    return (
      <LoaderComponent
        minHeight={"300px"}
        text={t("general.loadingProfile") || ""}
      />
    );
  }
  return <>{element}</>;
};
export default AuthenticatedRoute;
