import { AxiosError } from "axios";
import * as React from "react";
import ErrorPage from "./ErrorPage";

interface State {
  errorStatus: number;
  errorMessage: string;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren, State> {
  public state: State = {
    errorStatus: 0,
    errorMessage: "",
  };

  public static getDerivedStateFromError(error: AxiosError | Error): State {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        window.location.href = "/";
        return { errorStatus: 0, errorMessage: "" };
      }
      const errorData = axiosError.response?.data;
      let message = "";
      if (errorData) {
        const obj = errorData as { error: string };
        if (obj.error) {
          message = obj.error;
        } else {
          message = String(errorData);
        }
      }
      return {
        errorStatus: axiosError?.request?.status || 500,
        errorMessage: message,
      };
    }
    return { errorStatus: -1, errorMessage: error.message };
  }

  public render(): React.ReactNode {
    if (this.state.errorStatus) {
      return (
        <>
          <ErrorPage
            status={this.state.errorStatus}
            errorMessage={this.state.errorMessage}
            resetErrorCode={() =>
              this.setState({ errorStatus: 0, errorMessage: "" })
            }
          />
        </>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;

const isAxiosError = (error: Error): boolean => {
  return (error as AxiosError).isAxiosError;
};
