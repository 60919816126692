import { DropDown, DropDownOptionProp, useIsMobile } from "@tele2/t2-ui-kit";
import axios from "axios";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DetailsHeader, DetailsWrapper } from "../UserMenu";

const ChangeLanguage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();

  const availLngs = i18n.options.supportedLngs
    ? i18n.options.supportedLngs?.filter((lang: string) => {
        return lang !== "cimode";
      })
    : [];

  const languageOptions: DropDownOptionProp<string>[] = availLngs.map(
    (lang) => {
      return {
        dropdownLabel: t(`settings.language.${lang}`),
        option: lang,
      };
    }
  );

  const getLanguageOptionByValue = (value: unknown): number => {
    return languageOptions.findIndex((opt) => {
      return opt.option === value;
    });
  };

  return (
    <DetailsWrapper>
      {!isMobile && (
        <DetailsHeader xl>{t("settings.language.change")}</DetailsHeader>
      )}
      <DropDown<string>
        onSelect={async (value) => {
          await axios.post(`/api/localization?locale=${value}`);
          window.location.reload();
        }}
        options={languageOptions}
        selectedOption={
          languageOptions[getLanguageOptionByValue(i18n.language)]
        }
        widthCss="100%"
      />
    </DetailsWrapper>
  );
};

export default ChangeLanguage;
