import * as React from "react";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";

interface NavigationProps {
  pathname: string;
  pageNavigations: number;
  title: string;
}
interface GAContextValue {
  navigationProps: NavigationProps;
  setNavigationProps: React.Dispatch<React.SetStateAction<NavigationProps>>;
}

const props = { pageNavigations: 0, title: "", pathname: "" };
const GAContext = React.createContext<GAContextValue>({
  navigationProps: props,
  setNavigationProps: () => undefined,
});

const GAContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [navigationProps, setNavigationProps] = React.useState<NavigationProps>(
    props
  );
  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  React.useEffect(() => {
    if (navigationProps.pageNavigations > 1) {
      window.dataLayer.push({
        event: "page_load",
        page_path: navigationProps.pathname,
        page_title: navigationProps.title,
        segment: "my_tso",
      });
    }
  }, [navigationProps]);
  const contextValue = React.useMemo<GAContextValue>(() => {
    return {
      navigationProps,
      setNavigationProps,
    };
  }, [navigationProps]);
  return (
    <GAContext.Provider value={contextValue}>{children}</GAContext.Provider>
  );
};
export default GAContextProvider;

export const useGATracking = (title?: string | null) => {
  const { setNavigationProps } = React.useContext(GAContext);
  const location = useLocation();
  React.useEffect(() => {
    if (title) {
      setNavigationProps((prevState) => {
        return {
          pageNavigations: prevState.pageNavigations + 1,
          title,
          pathname: location.pathname,
        };
      });
    }
  }, [location.pathname, setNavigationProps, title]);
};
