import * as React from "react";
import { useTranslation } from "react-i18next";
import { Routes } from "react-router";
import { Route } from "react-router-dom";
import styled from "styled-components";
import AuthenticatedRoute from "./Components/AuthenticatedRoute/AuthenticatedRoute";
import ErrorPage from "./Components/ErrorHandler/ErrorPage";
import Footer from "./Components/SiteFooter/Footer";

import SiteHeader from "./Components/SiteHeader/SiteHeader";
import SuspenseBoundary from "./Components/SuspenseBoundary/SuspenseBoundary";
import { useGATracking } from "./Contexts/GAContext";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";

const Lazyload = (
  importStatement: () => Promise<{ default: React.ComponentType }>
) => React.lazy(importStatement);

const CallForwarding = Lazyload(
  () => import("./pages/CallForwarding/CallForwarding")
);
const CostsView = Lazyload(() => import("./pages/Costs/Costs"));
const InvoiceView = Lazyload(() => import("./pages/Invoice/Invoice"));
const MobileQueue = Lazyload(() => import("./pages/MobileQueue/MobileQueue"));
const Order = Lazyload(() => import("./pages/Order/Order"));
const BucketPaymentResult = Lazyload(
  () => import("./pages/PaymentResult/BucketPaymentResult")
);
const ConsumerServicePaymentResult = Lazyload(
  () => import("./pages/PaymentResult/ConsumerServicePaymentResult")
);
const PrivatePurchases = Lazyload(
  () => import("./pages/PrivatePurchases/PrivatePurchases")
);
const PrivatePurchasesPaymentReceipt = Lazyload(
  () => import("./pages/Receipt/ConsumerServiceReceipt")
);
const ReceiptView = Lazyload(() => import("./pages/Receipt/BucketReceipt"));
const RefreshForm = Lazyload(() => import("./pages/RefreshOrder/RefreshOrder"));
const SelectSubscription = Lazyload(
  () => import("./pages/SelectSubscription/SelectSubscription")
);
const SimInfo = Lazyload(() => import("./pages/SimInfo/SimInfo"));
const SmsTool = Lazyload(() => import("./pages/SmsTool/SmsTool"));
const Subscription = Lazyload(
  () => import("./pages/SingleSubscription/SingleSubscription")
);
const SubscriptionChart = Lazyload(() => import("./pages/DataUsage/DataUsage"));
const SubscriptionDetails = Lazyload(
  () => import("./pages/SubscriptionDetails/SubscriptionDetails")
);

export const paths = {
  BUCKET_PAYMENT_RESULT: "/subscription/:subsId/payment/bucket/:paymentId?",
  CALL_FORWARDING: "/subscription/:subsId/call-forwarding",
  CHART: "/subscription/:subsId/chart",
  CONSUMER_SERVICE_PAYMENT_RESULT:
    "/subscription/:subsId/payment/consumer-service/:paymentId?",
  COSTS: "/subscription/:subsId/costs",
  DATA_USAGE: "/subscription/:subsId/data-usage",
  INVOICE_DETAILS: "/subscription/:subsId/costs/invoices/:invoiceNr",
  LEGAL: "/legal",
  LOGIN: "/login",
  MOBILE_QUEUE: "/subscription/:subsId/mobile-queue",
  ORDER: "/order",
  PRIVATE_PURCHASES_PAYMENT_RECEIPT:
    "/subscription/:subsId/consumer-services/payment/:paymentId?",
  REFRESH: "/subscription/:subsId/refresh",
  REFRESHABLE_SUBSCRIPTIONS: "/refreshable-subscriptions",
  PRIVATE_PURCHASES: "/subscription/:subsId/consumer-services",
  RECEIPT_DETAILS: "/subscription/:subsId/costs/:bucketPurchaseId",
  SIM_INFO: "/subscription/:subsId/sim-info",
  SMS_TOOL: "/subscription/:subsId/sms-tool",
  SUBSCRIPTION: "/subscription/:subsId",
  SUBSCRIPTION_DETAILS: "/subscription/:subsId/details",
  COOKIE_POLICY: "/cookiepolicy",
};

export interface TSORouteProps {
  id?: string;
  title: string;
  breadCrumbIgnore?: boolean;
  path: string;
  Component: React.ExoticComponent;
  isSubscription?: boolean;
}

export const routes: TSORouteProps[] = [
  {
    Component: Subscription,
    isSubscription: true,
    path: paths.SUBSCRIPTION,
    title: "module.subscription.title",
  },
  {
    id: "subscriptionDetails",
    path: paths.SUBSCRIPTION_DETAILS,
    title: "module.subscriptionDetails.title",
    Component: SubscriptionDetails,
  },
  {
    id: "dataUsage",
    path: paths.DATA_USAGE,
    title: "module.dataUsage.title",
    Component: SubscriptionChart,
  },
  {
    id: "simInfo",
    path: paths.SIM_INFO,
    title: "module.simInfo.title",
    Component: SimInfo,
  },
  {
    id: "costs",
    path: paths.COSTS,
    title: "module.costs.title",
    Component: CostsView,
  },
  {
    breadCrumbIgnore: true,
    Component: InvoiceView,
    path: paths.INVOICE_DETAILS,
    title: "module.invoices.title",
  },
  {
    breadCrumbIgnore: true,
    Component: ReceiptView,
    path: paths.RECEIPT_DETAILS,
    title: "module.receipts.title",
  },
  {
    id: "callForwarding",
    path: paths.CALL_FORWARDING,
    title: "module.callForwarding.title",
    Component: CallForwarding,
  },
  {
    id: "smsTool",
    path: paths.SMS_TOOL,
    title: "module.smsTool.title",
    Component: SmsTool,
  },
  {
    id: "privatePurchases",
    path: paths.PRIVATE_PURCHASES,
    title: "module.privatePurchases.title",
    Component: PrivatePurchases,
  },
  {
    id: "privatePurchases",
    path: paths.PRIVATE_PURCHASES_PAYMENT_RECEIPT,
    title: "module.privatePurchases.title",
    Component: PrivatePurchasesPaymentReceipt,
  },
  {
    id: "refresh",
    path: paths.REFRESH,
    title: "module.refresh.title",
    Component: RefreshForm,
  },
  {
    id: "mobileQueue",
    path: paths.MOBILE_QUEUE,
    title: "module.mobileQueue.title",
    Component: MobileQueue,
  },
  {
    Component: SelectSubscription,
    path: paths.REFRESHABLE_SUBSCRIPTIONS,
    title: "module.refresh.myrefreshable",
  },
  {
    Component: SelectSubscription,
    path: "/",
    title: "module.selectSubscription.title",
  },
  {
    Component: BucketPaymentResult,
    path: paths.BUCKET_PAYMENT_RESULT,
    title: "module.payment.title",
  },
  {
    Component: ConsumerServicePaymentResult,
    path: paths.CONSUMER_SERVICE_PAYMENT_RESULT,
    title: "module.payment.title",
  },
  {
    Component: Order,
    path: paths.ORDER,
    title: "module.order.title",
  },
];

const RouteTitle: React.FC<React.PropsWithChildren<{
  title?: string;
}>> = (props) => {
  const { t } = useTranslation();
  const title = props.title ? t(props.title) : t("tso.my.pages");
  React.useEffect(() => {
    document.title = title;
  }, [title]);
  useGATracking(title);
  return <>{props.children}</>;
};

const Pages = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <>
          {routes.map(({ Component, title, ...props }) => (
            <Route
              key={props.path}
              {...props}
              element={
                <AuthenticatedRoute
                  element={
                    <RouteTitle title={title}>
                      <SiteHeader />
                      <FlexGrow>
                        <SuspenseBoundary>
                          <Component />
                        </SuspenseBoundary>
                      </FlexGrow>
                      <Footer />
                    </RouteTitle>
                  }
                />
              }
            />
          ))}
          <Route
            path="*"
            element={
              <AuthenticatedRoute
                element={
                  <RouteTitle title="error.404.header">
                    <ErrorPage status={404} />
                  </RouteTitle>
                }
              />
            }
          />
          <Route
            path={paths.COOKIE_POLICY}
            element={
              <RouteTitle title="module.cookie.policy">
                <CookiePolicy />
              </RouteTitle>
            }
          />
        </>
      </Routes>
    </>
  );
};

export default Pages;

const FlexGrow = styled.div`
  flex-grow: 1;
`;
