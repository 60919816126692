import * as React from "react";
import { useParams } from "react-router-dom";

interface ParamsProps {
  subsId?: string;
}

const useSubsId = (): number => {
  const params: ParamsProps = useParams();
  return React.useMemo(() => Number(params.subsId) || 0, [params.subsId]);
};

export default useSubsId;
