import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export type Status = "" | "PENDING" | "COMPLETE" | "FAILED";
export type HintCode = "" | "OUTSTANDING_TRANSACTION" | "USER_SIGN" | "UNKNOWN";

export interface BankIdCollectModel {
  status: Status;
  hintCode: HintCode;
  token: string;
}

type Endpoint = "AUTH" | "API";

export interface Parameters {
  orderRef: string;
  endpoint: Endpoint;
}

const httpCall = async (param: Parameters) => {
  const url =
    param.endpoint === "AUTH"
      ? `/auth/bankid/collect/${param.orderRef}`
      : `/api/bankid/collect/${param.orderRef}`;
  const { data } = await axios.get<BankIdCollectModel>(url);
  return data;
};

export const QUERY_KEY = "bank-id-login-collect";

const useBankIdLoginCollect = (param: Parameters) => {
  return useQuery<BankIdCollectModel, AxiosError>({
    queryKey: [QUERY_KEY, param.orderRef],
    queryFn: () => httpCall(param),
  });
};

export default useBankIdLoginCollect;
