import { useSuspenseQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { MyPagesModules } from "../../hooks/module-types";

const getPrincipalModules = async () => {
  const { data } = await axios.get<MyPagesModules[]>(
    `/api/subscriptions/principal-modules`
  );
  return data;
};
export const QUERY_KEY = "principal-modules";
const usePrincipalModules = () => {
  return useSuspenseQuery<MyPagesModules[], AxiosError>({
    queryKey: [QUERY_KEY],
    queryFn: () => getPrincipalModules(),
  });
};

export default usePrincipalModules;
