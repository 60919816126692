import * as React from "react";
import useActiveMobileSubscriptions from "./useActiveMobileSubscriptions";
import usePrincipalModules from "../api/subscriptions/usePrincipalModules";
import { SessionContext } from "../Contexts/SessionContext";
import { MyPagesCapabilities, MyPagesModules } from "./module-types";

const useCanOrder = () => {
  const { user } = React.useContext(SessionContext);
  const mobileSubscriptions = useActiveMobileSubscriptions();
  const { data: principalModules } = usePrincipalModules();
  return React.useMemo(() => {
    if ((mobileSubscriptions?.length || 0) === 0) {
      return false;
    }
    return (
      hasPrivatePurchase(principalModules ?? [], user?.bankIdAuth) ||
      hasEUData(
        principalModules ?? [],
        mobileSubscriptions?.map(({ capabilities }) => capabilities || []) || []
      ) ||
      hasRoamingData(
        principalModules ?? [],
        mobileSubscriptions?.map(({ capabilities }) => capabilities || []) || []
      )
    );
  }, [mobileSubscriptions, principalModules, user?.bankIdAuth]);
};
export default useCanOrder;

export const hasEUData = (
  principalModules: MyPagesModules[],
  capabilities: MyPagesCapabilities[][]
): boolean => {
  if (!principalModules?.includes(MyPagesModules.SA_EXTRA_DATA_EU)) {
    return false;
  }
  return capabilities
    .flat()
    .some((cap) => cap === MyPagesCapabilities.EXTRA_DATA_EU);
};

export const hasRoamingData = (
  principalModules: MyPagesModules[],
  capabilities: MyPagesCapabilities[][]
): boolean => {
  if (!principalModules.includes(MyPagesModules.SA_ROAMING_DATA_BUCKET)) {
    return false;
  }
  return capabilities
    .flat()
    .some((cap) => cap === MyPagesCapabilities.ROAMING_DATA_BUCKET);
};

const hasPrivatePurchase = (
  principalModules: MyPagesModules[],
  bankIdAuth?: boolean
): boolean => {
  return Boolean(
    principalModules?.includes(MyPagesModules.MY_PAGES_PRIVATE_PROCUREMENT) &&
      bankIdAuth
  );
};
