import { QueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // One minute
      gcTime: 1800000, // 30 minutes
      refetchOnWindowFocus: false,
      throwOnError: true, // Throw errors to be picked up by error boundary. Hooks outside boundary must have useErrorBoundary: false
      retry: (failureCount, error) => {
        if (isAxiosError(error as Error)) {
          const axiosError = error as AxiosError;
          if (
            (axiosError.response?.status || 400) >= 400 &&
            (axiosError.response?.status || 400) < 500
          ) {
            return false;
          }
        }
        return failureCount < 3;
      },
    },
    // mutations: {
    //   throwOnError: true,
    // },
  },
});

export default queryClient;
