import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMessage } from "../../Components/Message/Message";

interface Otp1FAInput {
  userName: string;
  password: string;
}

interface OtpModel {
  otpSmsNumber: string;
  timeout: number;
  message: string;
  error: string;
}

const otpLogin = async (input: Otp1FAInput) => {
  const formData = new FormData();
  formData.set("password", input.password);
  formData.set("username", input.userName);
  const { data } = await axios.post("/auth/login-otp-1fa", formData);
  return data;
};

const useOtp1FA = () => {
  const message = useMessage();
  const { t } = useTranslation();

  const specificError = (responseCode: number) =>
    responseCode === 401
      ? t("module.login.otpError")
      : t(`error.${responseCode}.message`);

  return useMutation<OtpModel, AxiosError, Otp1FAInput>({
    mutationFn: otpLogin,
    onSuccess: async () => {
      // Reload on success to reload third party scripts
      window.location.reload();
    },
    onError: (error) => {
      const errorMessage = error.response?.status
        ? specificError(error.response.status)
        : t("error.500.message");
      message({
        interval: 5000,
        message: errorMessage,
        theme: "alert",
      });
    },
  });
};

export default useOtp1FA;
